import { BaseSpinner, Section } from '@sweb-front/components';
import {
  selectNavigation,
  setEndParcours,
  setLastAppUrl,
  setSEDecision,
  updateOpportunityStatus,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import {
  IThreeDsResponse,
  Monext3DsResponse,
  MonextResponse,
  TParamWaitingPage,
} from '@sweb-front/types';
import {
  ERRORPAGE,
  INFORMATIONBANCAIRES,
  PIECESJUSTIFICATIVES,
  REDIRECTION,
  REFUSEDREPONSE,
  SUCCESSREPONSE,
  THREEDS,
  WAITINGRESPONSE,
} from '@vat/configuration';
import {
  updateCardPayment,
  check3DSReturnCode,
  createECard,
} from '@vat/services';
import {
  ErrorContext,
  useManagingExternalApp,
  useCloseOpportunity,
  PageLoadingContext,
} from '@vat/utils';
import { useAcceptationPolicy, useObResultTreatment } from '@sweb-front/hooks';
import { LoadingWrapper } from '@sweb-front/styles';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isStringEmpty } from '@sweb-front/utils';
import { SpinnerLabel, SpinnerLongText } from './styles';
import { useNavigate } from 'react-router-dom';

type IWaitingPageProps = {
  waitingStep: string;
  paramsWaitingPage?: TParamWaitingPage;
};

const WaitingPage = ({ waitingStep, paramsWaitingPage }: IWaitingPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const updateError = useContext(ErrorContext);
  const { getAcceptationPolicy } = useAcceptationPolicy();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const { init3DS, finalizeLoanRequesting, waitingPageBeforeOnbo } =
    useManagingExternalApp();
  const { processObResult } = useObResultTreatment();
  const appNavigation = useAppSelector(selectNavigation);
  const updateIsLoading = useContext(PageLoadingContext);
  const navigate = useNavigate();

  const goToPreviousPage = useCallback(() => {
    dispatch(
      updateSteps({
        externalAppName: 'monext',
        isExternalUrlAlreadyVisited: false,
      })
    );
    navigate(appNavigation.lastAppUrl, {
      replace: true,
    });
  }, [appNavigation]);

  const vatCbOnboWaitingPage = useCallback(() => {
    const threeDsReturnCode: IThreeDsResponse =
      paramsWaitingPage as IThreeDsResponse;

    if (
      (!threeDsReturnCode || !threeDsReturnCode.returnCode) &&
      threeDsReturnCode.sign === threeDsReturnCode.storedSign
    ) {
      init3DS(paramsWaitingPage, () => {
        dispatch(
          updateParcoursNavigation({
            name: PIECESJUSTIFICATIVES,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isErrorHappened: false,
            isSeDone: true,
            redirectionParam: 'redirectFromSignature',
          })
        );
      });
    } else if (threeDsReturnCode.sign === threeDsReturnCode.storedSign) {
      check3DSReturnCode(
        threeDsReturnCode.returnCode,
        (response) => {
          if (response.scoreLightCd === 'R') {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            dispatch(setEndParcours(true));
            dispatch(updateOpportunityStatus('REFU'));
            dispatch(setLastAppUrl(REFUSEDREPONSE));
            navigate(REFUSEDREPONSE);
          } else if (response.pspReturnCd === '00') {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            init3DS(paramsWaitingPage, () => {
              dispatch(
                updateSteps({
                  externalAppName: 'onbo',
                  isErrorHappened: false,
                  isSeDone: true,
                  redirectionParam: 'redirectFromSignature',
                })
              );
            });
          } else {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: false,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            updateAbortErrorMessage(
              ` Service onboarding/esign Ok mais la réponse "${
                response?.scoreLightCd ?? '-'
              }/${
                response?.pspReturnCd ?? '-'
              }" n'est pas contrôlée pour faire une action`
            );
            updateError();
          }
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              loaded: true,
              actionPageDone: false,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateAbortErrorMessage(
            ` Problème avec le service onboarding/esign avec comme retour Onbo ${Object.entries(
              threeDsReturnCode ?? {}
            )
              .map(([key, value]) => `${key}: ${value || '-'}`)
              .join('; ')}`
          );
          updateError();
        }
      );
    } else {
      dispatch(
        updateParcoursNavigation({
          name: PIECESJUSTIFICATIVES,
          loaded: true,
          actionPageDone: true,
          disabled: false,
          params: {
            hasError: true,
          },
        })
      );
      updateAbortErrorMessage(
        ` Un souci sur l'appli Onbo (le service onboarding/esign n'est pas lancé à ce stade) "${Object.entries(
          threeDsReturnCode ?? {}
        )
          .map(([key, value]) => `${key}: ${value || '-'}`)
          .join(
            '; '
          )}", ou Peut être l'utilisateur a essayé de manuellement taper l'url de retour onbo`
      );
      updateError();
    }
  }, [paramsWaitingPage]);

  const checkScoreVATRIB = async () => {
    dispatch(
      updateSteps({
        externalAppName: 'onbo',
        isErrorHappened: false,
        isSeDone: true,
        redirectionParam: 'redirectFromSignature',
      })
    );
    dispatch(
      updateParcoursNavigation({
        name: PIECESJUSTIFICATIVES,
        loaded: true,
        actionPageDone: true,
        disabled: false,
      })
    );

    try {
      const response = await getAcceptationPolicy('RIB', 'ONBO', () => {});
      const scoreLight = response?.data?.scoreLightCd;
      dispatch(setEndParcours(true));
      if (scoreLight === 'V') {
        dispatch(updateOpportunityStatus('APPR'));
        dispatch(setLastAppUrl(SUCCESSREPONSE));
        navigate(SUCCESSREPONSE);
      } else if (scoreLight === 'R') {
        dispatch(updateOpportunityStatus('REFU'));
        dispatch(setLastAppUrl(REFUSEDREPONSE));
        navigate(REFUSEDREPONSE);
      } else {
        dispatch(updateOpportunityStatus('PAPP'));
        dispatch(setLastAppUrl(WAITINGRESPONSE));
        navigate(WAITINGRESPONSE);
      }
    } catch {
      dispatch(
        updateSteps({
          externalAppName: 'onbo',
          isErrorHappened: true,
          isSeDone: false,
          redirectionParam: 'redirectFromSignature',
        })
      );
      updateAbortErrorMessage('getScore en erreur à la fin du parcours RIB');
      navigate(ERRORPAGE, {
        replace: true,
      });
    }
  };

  const vatRIBOnboWaitingPage = useCallback(async () => {
    const threeDsReturnCode: IThreeDsResponse =
      paramsWaitingPage as IThreeDsResponse;

    if (
      (!threeDsReturnCode || !threeDsReturnCode.returnCode) &&
      threeDsReturnCode.sign === threeDsReturnCode.storedSign
    ) {
      const token = localStorage.getItem('token') ?? '';
      if (!isStringEmpty(token) && !!appNavigation.rightToEcard) {
        await createECard(token).catch(() => {
          updateAbortErrorMessage(` Service de création e-card KO`);
          dispatch(
            updateSteps({
              externalAppName: 'onbo',
              isErrorHappened: true,
              isSeDone: false,
              redirectionParam: 'redirectFromSignature',
            })
          );
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              loaded: true,
              actionPageDone: true,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          navigate(ERRORPAGE, {
            replace: true,
          });
        });
      }
      await checkScoreVATRIB(); // acceptation policy
    } else if (threeDsReturnCode.sign === threeDsReturnCode.storedSign) {
      check3DSReturnCode(
        // esignature
        threeDsReturnCode.returnCode,
        async (response) => {
          if (response.scoreLightCd === 'R') {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            dispatch(setEndParcours(true));
            dispatch(updateOpportunityStatus('REFU'));
            dispatch(setLastAppUrl(REFUSEDREPONSE));
            navigate(REFUSEDREPONSE);
          } else {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            updateAbortErrorMessage(
              ` Service onboarding/esign Ok mais la réponse "${
                response?.scoreLightCd ?? '-'
              }/${
                response?.pspReturnCd ?? '-'
              }" n'est pas contrôlée pour faire une action`
            );
            updateError();
          }
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              loaded: true,
              actionPageDone: true,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateAbortErrorMessage(
            ` Problème avec le service onboarding/esign avec comme retour Onbo "${Object.entries(
              threeDsReturnCode ?? {}
            )
              .map(([key, value]) => `${key}: ${value ?? '-'}`)
              .join('; ')}"`
          );
          updateError();
        }
      );
    } else {
      dispatch(
        updateParcoursNavigation({
          name: PIECESJUSTIFICATIVES,
          loaded: true,
          actionPageDone: true,
          disabled: false,
          params: {
            hasError: true,
          },
        })
      );
      updateAbortErrorMessage(
        ` Un souci sur l'appli Onbo (le service onboarding/esign n'est pas lancé à ce stade) "${Object.entries(
          threeDsReturnCode ?? {}
        )
          .map(([key, value]) => `${key}: ${value || '-'}`)
          .join(
            '; '
          )}", ou Peut être l'utilisateur a essayé de manuellement taper l'url de retour onbo`
      );
      updateError();
    }
  }, []);

  useEffect(() => {
    if (waitingStep === 'attente-cb') {
      const monextResponse = paramsWaitingPage as unknown as MonextResponse;
      dispatch(
        updateSteps({
          externalAppName: 'monext',
          params: monextResponse as unknown as Record<string, unknown>,
        })
      );

      updateCardPayment(
        monextResponse?.cardId,
        monextResponse?.cardRef,
        monextResponse?.returnCode,
        monextResponse?.returnValue,
        (response) => {
          dispatch(setSEDecision(response));
          const seDecision = response;
          if (seDecision.pspReturnCd === '12') {
            dispatch(
              updateParcoursNavigation({
                name: INFORMATIONBANCAIRES,
                actionPageDone: false,
                disabled: false,
              })
            );
            goToPreviousPage();
          } else if (seDecision?.pspReturnCd === 'ERROR') {
            dispatch(
              updateParcoursNavigation({
                name: INFORMATIONBANCAIRES,
                actionPageDone: false,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            updateError();
            dispatch(
              updateSteps({
                externalAppName: 'monext',
                waitingPagePath:
                  window.location.pathname + window.location.search,
                isErrorHappened: true,
              })
            );
          } else {
            dispatch(
              updateParcoursNavigation({
                name: INFORMATIONBANCAIRES,
                loaded: true,
                actionPageDone: true,
                locked: true,
                disabled: false,
              })
            );
            waitingPageBeforeOnbo();
          }
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: INFORMATIONBANCAIRES,
              loaded: true,
              actionPageDone: false,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateAbortErrorMessage(
            isStringEmpty(monextResponse?.cardRef)
              ? "les paramètres CardId et/ou CardRef venant de monext sont absents ou n'ont pas de valeurs."
              : `Un problème au niveau du service bank-card est détecté ${Object.entries(
                  monextResponse ?? ({} as MonextResponse)
                )
                  .map(([key, value]) => `${key}: "${value}"`)
                  .join('; ')}`
          );
          updateError();
          dispatch(
            updateSteps({
              externalAppName: 'monext',
              waitingPagePath:
                window.location.pathname + window.location.search,
              isErrorHappened: true,
            })
          );
        }
      );
    } else if (waitingStep === 'attente-onboarding') {
      vatCbOnboWaitingPage();
    } else if (waitingStep === 'attente-onboarding-rib') {
      vatRIBOnboWaitingPage();
    } else if (waitingStep === 'attente-3ds') {
      const monext3DsResponse =
        paramsWaitingPage as unknown as Monext3DsResponse;
      if (monext3DsResponse && monext3DsResponse.threeDsRef) {
        dispatch(
          updateSteps({
            externalAppName: '3ds',
            params: paramsWaitingPage,
            isErrorHappened: false,
            isSeDone: true,
          })
        );
        dispatch(
          updateParcoursNavigation({
            name: THREEDS,
            actionPageDone: true,
          })
        );
        finalizeLoanRequesting(monext3DsResponse);
      } else {
        navigate(REDIRECTION);
      }
    } else if (waitingStep === 'attente-openbanking') {
      processObResult();
    }

    return () => {
      updateIsLoading(false);
    };
  }, [waitingStep]);

  return (
    <Section>
      <LoadingWrapper>
        <BaseSpinner size="large" color="green" />
        <SpinnerLabel>{t('waitingPage.label')}</SpinnerLabel>
        <SpinnerLongText>{t('waitingPage.longText')}</SpinnerLongText>
      </LoadingWrapper>
    </Section>
  );
};

export default WaitingPage;
