import { Section } from '@sweb-front/components';
import { useAppSelector } from '@sweb-front/store';
import { ascSort } from '@sweb-front/utils';
import {
  FORM_FIELDS_MAPPING,
  INFORMATIONPAGE,
  INFORMATIONS,
} from '@vat/configuration';
import { InformationForm } from '@vat/containers';
import { selectDisplays } from '@sweb-front/store';
import { useTranslation } from 'react-i18next';
import { usePageRedirection } from '@sweb-front/hooks';
import {
  CustomedBubbleInfoWrapper,
  SectionContainer,
  BannerWrapper,
} from './styles';
import { useEffect, useState } from 'react';

const Information = () => {
  const { t } = useTranslation();
  const displays = useAppSelector(selectDisplays);
  const kycFields = Object.keys(FORM_FIELDS_MAPPING.KYC).sort((k, l) =>
    ascSort(FORM_FIELDS_MAPPING.KYC[k], FORM_FIELDS_MAPPING.KYC[l])
  );
  const { isPageLoading } = usePageRedirection(INFORMATIONS);
  const [showBubbleInfo, setShowBubbleInfo] = useState(false);
  const [bubbleInfoHiddenByAction, setBubbleInfoHiddenByAction] =
    useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowBubbleInfo(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [getInformationFields] = (displays ?? [])
    .filter((d) => d.displayCd === 'KYC')
    .map((df) => df.fields.split(','));

  const fields = kycFields.filter((f) =>
    (getInformationFields ?? []).includes(f)
  );

  return (
    !isPageLoading && (
      <>
        <SectionContainer id="section-container">
          {fields.includes(INFORMATIONPAGE.KYCBLOC4) && (
            <BannerWrapper
              className={
                showBubbleInfo && fields.includes(INFORMATIONPAGE.KYCBLOC4)
                  ? 'open'
                  : `close ${bubbleInfoHiddenByAction ? 'clear-component' : ''}`
              }
            >
              <CustomedBubbleInfoWrapper
                id={`modal-kyc-cin`}
                description={t('basicInformations.bubbleInfo.kyc')}
                onClose={() => {
                  setBubbleInfoHiddenByAction(true);
                  setShowBubbleInfo((prev) => !prev);
                }}
                hasClosebtn
              />
            </BannerWrapper>
          )}
          <Section id="section" title={t('basicInformations.title')}>
            <InformationForm fields={fields} />
          </Section>
        </SectionContainer>
      </>
    )
  );
};

export default Information;
