import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  useBreakpoints,
  useLoadingHook,
  useManagingStep,
  useUrlWebMarchand,
} from '@sweb-front/hooks';
import {
  useAppOptions,
  capitalize,
  phoneFormatter,
  wholeWordCapitalize,
  ErrorContext,
  useCloseOpportunity,
} from '@vat/utils';
import { axiosInstance, COUNTRY_ID } from '@sweb-front/services';
import {
  IOpportunityState,
  IParameterState,
  IReferentielState,
  selectNavigation,
  setAddress,
  setAutocompletefieldValue,
  setCities,
  setCountries,
  setDepartements,
  setEmail,
  setIsFilledWithAutoComplete,
  setNationalities,
  setPersonalInformation,
  setPhone,
  updateOpportunityStatus,
  updateParcoursNavigation,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import {
  IOpportunity,
  IPersonalInformation,
  ISelectCombo,
} from '@sweb-front/types';
import { sha256 } from 'js-sha256';
import { isStringEmpty, trackEvent, trackUser } from '@sweb-front/utils';
import {
  INFORMATIONPAGE,
  INFORMATIONS,
  OPPORTUNITYSTATUS,
} from '@vat/configuration';
import { AxiosResponse } from 'axios';
import {
  BaseInputDS,
  ComboInputDS,
  IListItem,
  TCardItem,
} from '@sweb-front/components';

import {
  arrayRange,
  customValidityPattern,
  fetchData,
  getBirthDtValue,
  getMatchedItem,
  getYearErrorMessage,
  isAutocompleteInvalid,
  isCityInvalidForFranceOrOther,
  isComboInputInvalid,
  isDateInvalid,
  isInvalidTextInput,
  pad,
  postData,
  removeSpecialCharacters,
  replaceAccents,
  scrollToFirstInvalidElement,
  setIdHashed,
} from '../utils';
import { KYCEDITABLEFIELDS } from './InformationForm';
import { saveXtn } from '@vat/services';
import {
  SECTORCODES,
  SECTORLIST,
} from '../IncomeExpensesForm/IncomeExpensesConstants';
import { setProfession } from 'src/libs/store/opportunity/opportunitySlice';
import { IProfessionInput } from 'src/libs/types/PersonnalInformations';
import { isMobile as isMobileDevice } from 'react-device-detect';

export type UseInformationFormOptions = {
  opportunity: IOpportunityState;
  referentiels: IReferentielState;
  parameters: IParameterState;
  textPattern: string;
  fields: string[];
};

const compareString = (
  field: string,
  originalValue: string,
  toCompare: string
) => {
  if (
    !isStringEmpty(originalValue) &&
    (originalValue ?? '').toLocaleLowerCase().replace(/[,\; ]+/, '') !==
      (toCompare ?? '').toLocaleLowerCase().replace(/[\,; ]+/, '')
  ) {
    trackEvent({
      event: 'user_interaction',
      completionArea: field,
    });
  }
};

const wordCompare = (field: string, original: string, toCompare: string) => {
  compareString(field, original, toCompare);
};

const useInformationForm = ({
  textPattern,
  fields,
  opportunity,
  referentiels,
  parameters,
}: UseInformationFormOptions) => {
  const appNavUtils = useAppSelector(selectNavigation);
  const [showAutoCompletedField, setShowAutoCompletedField] = useState<boolean>(
    appNavUtils.isFilledWithAutoComplete ?? true
  );
  const { t } = useTranslation();
  const updateError = useContext(ErrorContext);
  const regExpInvalidCharacters = new RegExp(textPattern);
  const dispatch = useAppDispatch();

  const { person } = opportunity;
  const { goToNextStep, goToWebMarchand } = useManagingStep();
  const { getUrlCancel } = useUrlWebMarchand();
  const [departementCode, setDepartementCode] = useState<string | undefined>();
  const formRef = useRef<HTMLFormElement | null>(null);
  const cityRef = useRef<ComboInputDS>(null);
  const freeCityRef = useRef<BaseInputDS>(null);
  const [buttonClicked, setButtonClicked] = useState(0);
  const [isFormValid, setIsFormValid] = useState<boolean>();
  const invalidityState: { [index: string]: boolean | null } = {};
  const [age, setAge] = useState<number | null>(null);
  const [year, setYear] = useState<number | undefined>(undefined);
  const [day, setDay] = useState<number | undefined>(undefined);
  const [month, setMonth] = useState<number | undefined>(undefined);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const [isLoading, setIsLoading] = useState(false);

  const [dopcOptin, setDopcOptin] = useState({
    optin: person?.cellPhone?.useBo ?? false, // we just need the cellPhone or email as they will have the same behaviour and value for the optin
    dopc: person?.address?.useBo ?? false, // dopc
  });

  const onUpdateOptin = (value: boolean) => {
    setDopcOptin((prev) => ({
      ...prev,
      optin: value,
    }));
    dispatch(
      setEmail({
        ...person.email,
        useBo: value,
      })
    );
    dispatch(
      setPhone({
        ...person.cellPhone,
        useBo: value,
      })
    );
  };

  const onUpdateDopc = (value: boolean) => {
    setDopcOptin((prev) => ({
      ...prev,
      dopc: value,
    }));
    dispatch(
      setAddress({
        ...person.address,
        useBo: value,
      })
    );
  };

  // Manage modal to show/hide redirection when click on edit
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalLeave, setShowModalLeave] = useState<boolean>(false);
  const onShowModal = useCallback((val: boolean) => {
    setShowModal(val);
  }, []);

  const onShowModalLeave = useCallback((val: boolean) => {
    setShowModalLeave(val);
  }, []);

  const onShowModalLeaveFn = useCallback(() => {
    if (isLoading) {
      return;
    }
    onShowModalLeave(false);
  }, [isLoading]);

  // Manage Combo fields validity
  const [isBirthCountryValid, setIsBirthCountryValid] = useState<boolean>(true);

  const [isBirthDepartmentValid, setIsBirthDepartmentValid] =
    useState<boolean>(true);
  const [isBirthCityValid, setIsBirthCityValid] = useState<boolean>(true);
  const [isNationalityIsoCdValid, setIsNationalityIsoCdValid] =
    useState<boolean>(true);

  const [isUnmount, setIsUnmount] = useState<boolean>(false);

  const removeCityDepartementAttributes = useCallback(() => {
    cityRef?.current?.removeAttribute('valid');
    cityRef?.current?.removeAttribute('invalid');
    freeCityRef?.current?.removeAttribute('invalid');
    freeCityRef?.current?.removeAttribute('valid');
  }, [cityRef, freeCityRef]);

  const { setAppOptions } = useAppOptions();
  useEffect(() => {
    if (opportunity?.opportunityIdExt != null) {
      trackUser(opportunity?.opportunityIdExt);
    }
    setIsUnmount(false);
    setAppOptions((state) => ({ ...state, isMounted: true }));
    return () => {
      setIsUnmount(true);
    };
  }, []);

  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const [selectedRecordAutocomplete, setSelectedRecordAutocomplete] =
    useState<Record<string, string>>();
  const allFields = {
    birthCity: fields.includes(INFORMATIONPAGE.KYCVILNAI) ? '' : undefined,
    birthCountryIsoCd: fields.includes(INFORMATIONPAGE.KYCPAYSNAI)
      ? ''
      : undefined,
    birthDepartment: fields.includes(INFORMATIONPAGE.KYCDPTNAI)
      ? ''
      : undefined,
    birthName: fields.includes(INFORMATIONPAGE.KYCNOMNAI) ? '' : undefined,
    birthDt: fields.includes(INFORMATIONPAGE.KYCDTNAI) ? '' : undefined,
    nationalityIsoCd: fields.includes(INFORMATIONPAGE.KYCNATIO)
      ? ''
      : undefined,
    activitySector: fields.includes(INFORMATIONPAGE.SECTEUR) ? '' : undefined,
    title: fields.includes(INFORMATIONPAGE.KYCBLOC1) ? '' : undefined,
    name: fields.includes(INFORMATIONPAGE.KYCBLOC4) ? '' : undefined,
    firstName: fields.includes(INFORMATIONPAGE.KYCBLOC4) ? '' : undefined,
    autocompleteBirthCity: showAutoCompletedField ? '' : undefined,
  } as Record<string, string>;

  const sectors = useMemo(
    () =>
      SECTORLIST.map((sector) => ({
        value: SECTORCODES[sector],
        label: t(`incomesAndExpenses.form.sectorList.${sector}`),
      })),
    []
  );

  const mobileSectors = SECTORLIST.map((sector) => ({
    value: SECTORCODES[sector],
    displayValue: t(`incomesAndExpenses.form.sectorList.${sector}`),
  }));

  const { isDesktop } = useBreakpoints();

  /** for specific customer, some fields are not present and validation must be done onnly with its fields */
  const fieldsToRemove = Object.keys(allFields).filter(
    (field) => allFields[field] === undefined
  );

  Object.keys(allFields).forEach((element) => {
    invalidityState[element] = false;
    if (element === KYCEDITABLEFIELDS.ActivitySector) {
      invalidityState[element] = null;
    }
  });

  const [invalidityFieldsStates, setInvalidityFieldStates] =
    useState(invalidityState);

  const fieldsValidity = {
    ...allFields,
    ...person?.personalInformation,
    autocompleteBirthCity: autocompleteValue,
    activitySector: person?.profession?.ihmSector,
  } as Record<string, string>;

  Object.keys(fieldsValidity).forEach((f) => {
    if (fieldsToRemove.includes(f)) {
      delete fieldsValidity[f];
    }
  });

  const countryCard = useMemo(() => {
    if (person && person.address.countryCd) {
      return capitalize(
        getMatchedItem(
          person.address.countryCd,
          'test',
          referentiels?.countries
        )?.name ?? capitalize(person.address.countryCd)
      );
    }
    return '';
  }, [person, referentiels, opportunity?.person?.address?.countryCd]);

  const personalDetails: TCardItem[] = useMemo(
    () => [
      {
        title: t('basicInformations.address'),
        description: capitalize(person?.address?.streetAddress ?? ''),
      },
      {
        title: t('basicInformations.postalCode'),
        description: person?.address?.zipCd,
      },
      {
        title: t('basicInformations.city'),
        description: capitalize(person?.address?.city ?? ''),
      },
      {
        title: t('basicInformations.country'),
        description: countryCard,
      },
    ],
    [person, referentiels, opportunity?.person?.address]
  );

  const contentDetails: TCardItem[] = useMemo(
    () => [
      {
        title: t('basicInformations.email'),
        description: (person?.email?.address ?? '').toLocaleLowerCase(),
      },
      {
        title: t('basicInformations.phoneNumber'),
        description: phoneFormatter(person?.cellPhone?.phoneNb ?? ''),
      },
    ],
    [person, opportunity?.person?.address, opportunity?.person?.cellPhone]
  );

  useEffect(() => {
    fetchData(
      'localities/v1/countries',
      {
        headers: {
          countryID: COUNTRY_ID,
          token: localStorage.getItem('token'),
        },
      },
      (data) => {
        dispatch(setCountries(data));
        dispatch(setNationalities(data));
      },
      () => {
        updateAbortErrorMessage(
          'kyc::fetchCountries an error occured when getting the countries'
        );
        updateError();
      }
    );
    if (!showAutoCompletedField) {
      fetchData(
        'localities/v1/departments',
        {
          headers: {
            countryID: COUNTRY_ID,
            token: localStorage.getItem('token'),
          },
        },
        (data) => {
          dispatch(setDepartements(data));
        },
        () => {
          updateAbortErrorMessage(
            'kyc::fetchDepartments an error occured when getting the departments'
          );
          updateError();
        }
      );
    }
  }, [showAutoCompletedField]);

  useEffect(() => {
    /* eslint no-underscore-dangle: 0 */
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity.offers[0].loans[0];
      trackEvent({
        event: 'pageLoaded',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Formulaire KYC',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: (financialDetails.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails.tncRt as number) ?? undefined,
        Term: (financialDetails.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: Number(opportunity.distributor.distributorNb),
      });
    }
  }, []);

  useEffect(() => {
    setDepartementCode(person?.personalInformation?.birthDepartment ?? '');
  }, [
    person?.personalInformation?.birthDepartment,
    opportunity?.person?.personalInformation?.birthDepartment,
  ]);

  const [isBirthCountryFR, setIsBirthCountryFR] = useState<boolean>();

  // Add some controls to control the age of the user
  const minYear = new Date().getFullYear() - 100;
  const maxYear = new Date().getFullYear() - 18;
  const maxMonth = new Date().getMonth() + 1;
  const maxDate = new Date().getDate();

  const allowedYearList = arrayRange(minYear, maxYear - 1, 1);
  const allowedMonthList = arrayRange(1, maxMonth - 1, 1).map((m) => pad(m));
  const allowedDayList = arrayRange(1, maxDate, 1).map((d) => pad(d));

  useEffect(() => {
    const fieldsToCheck = {
      ...fieldsValidity,
    } as Record<string, string>;

    if (!isBirthCountryFR) {
      Object.keys(fieldsToCheck).forEach((key) => {
        if (
          [
            KYCEDITABLEFIELDS.BirthCityInseeCd,
            KYCEDITABLEFIELDS.BirthDepartment,
          ].includes(key as KYCEDITABLEFIELDS)
        ) {
          delete fieldsToCheck[key];
        }
      });
    }

    if (showAutoCompletedField) {
      Object.keys(fieldsToCheck).forEach((key) => {
        if (
          [
            KYCEDITABLEFIELDS.BirthCountry,
            KYCEDITABLEFIELDS.BirthCity,
            KYCEDITABLEFIELDS.BirthCityInseeCd,
            KYCEDITABLEFIELDS.BirthDepartment,
          ].includes(key as KYCEDITABLEFIELDS)
        ) {
          delete fieldsToCheck[key];
        }
      });
    } else {
      delete fieldsToCheck.autocompleteBirthCity;
    }

    setIsFormValid(
      !Object.keys(fieldsToCheck).some((key) => {
        if (['birthName', 'name', 'firstName'].includes(key)) {
          return isInvalidTextInput(fieldsToCheck[key]);
        }
        if (key === 'birthDt') {
          return isDateInvalid(fieldsToCheck[key]);
        }

        if (key === 'autocompleteBirthCity') {
          return autocompleteValue === undefined
            ? isAutocompleteInvalid(
                appNavUtils?.autoCompleteBirthCityValue
                  ?.autocompleteValue as string,
                appNavUtils?.autoCompleteBirthCityValue
                  ?.selectedRecordAutocomplete as Record<string, string>
              )
            : isAutocompleteInvalid(
                fieldsToCheck[key],
                selectedRecordAutocomplete
              );
        }
        if (key === KYCEDITABLEFIELDS.BirthCountry) {
          return isComboInputInvalid(fieldsToCheck[key], isBirthCountryValid);
        }
        if (key === KYCEDITABLEFIELDS.BirthDepartment) {
          return isComboInputInvalid(
            fieldsToCheck[key],
            isBirthDepartmentValid
          );
        }
        if (key === KYCEDITABLEFIELDS.BirthCity) {
          return isCityInvalidForFranceOrOther(
            fieldsToCheck[key],
            isBirthCityValid,
            regExpInvalidCharacters,
            isBirthCountryFR
          );
        }

        if (key === KYCEDITABLEFIELDS.NationalityIsoCd) {
          return isComboInputInvalid(
            fieldsToCheck[key],
            isNationalityIsoCdValid
          );
        }

        if ([KYCEDITABLEFIELDS.ActivitySector, 'title'].includes(key)) {
          return isStringEmpty(fieldsToCheck[key]);
        }

        return typeof fieldsToCheck[key] === 'string'
          ? isStringEmpty(fieldsToCheck[key])
          : false;
      })
    );
  }, [
    fieldsValidity,
    isBirthCountryValid,
    isBirthCountryFR,
    isBirthDepartmentValid,
    isBirthCityValid,
    isNationalityIsoCdValid,
    showAutoCompletedField,
    appNavUtils?.autoCompleteBirthCityValue,
    selectedRecordAutocomplete,
  ]);

  useEffect(() => {
    if (buttonClicked < 1) {
      return;
    }
    scrollToFirstInvalidElement(formRef);
  }, [formRef?.current, buttonClicked]);

  useEffect(() => {
    setIsBirthCountryFR(
      person?.personalInformation?.birthCountryIsoCd === 'FR'
    );
  }, []);

  useEffect(() => {
    const regex = new RegExp('^[A-Z0-9]{2,3}$', 'i');
    if (
      !showAutoCompletedField &&
      departementCode &&
      isBirthDepartmentValid &&
      regex.test(departementCode) &&
      isBirthCountryFR
    ) {
      axiosInstance()
        .get(`localities/v1/${departementCode}/cities`, {
          headers: { countryID: COUNTRY_ID },
        })
        .then((response: AxiosResponse<ISelectCombo[]>) => {
          dispatch(setCities(response?.data ?? []));
          // Don't reset cities if the city exists in the cities
          if (
            (response?.data ?? []).filter(
              (city) =>
                city.name.toUpperCase() ===
                (person.personalInformation.birthCity ?? '').toUpperCase()
            ).length <= 0
          ) {
            dispatch(
              setPersonalInformation({
                [KYCEDITABLEFIELDS.BirthCity]: '',
                [KYCEDITABLEFIELDS.BirthCityInseeCd]: '',
              } as IPersonalInformation)
            );
            setIsBirthCityValid(false);
            removeCityDepartementAttributes();
          }
        })
        .catch(() => {
          updateAbortErrorMessage(
            'kyc::getCities an error occured when getting the cities'
          );
          updateError();
        });
    }
  }, [
    departementCode,
    isBirthDepartmentValid,
    cityRef,
    freeCityRef,
    showAutoCompletedField,
    isBirthCountryFR,
  ]);

  const updateOpportunities = useCallback(() => {
    const updateOpportunity: IOpportunity = {
      persons: [
        {
          ...person,
          personalInformation: {
            ...person.personalInformation,
            birthDepartment: isBirthCountryFR
              ? person.personalInformation.birthDepartment
              : undefined,
            birthCityInseeCd: isBirthCountryFR
              ? person.personalInformation.birthCityInseeCd
              : undefined,
          },
        },
      ],
      distritutor: opportunity.distributor,
      opportunityIdExt: opportunity.opportunityIdExt,
      project: opportunity.project,
      isMobile: isMobileDevice,
    };

    if (
      !fields.includes(INFORMATIONPAGE.SECTEUR) &&
      updateOpportunity.persons
    ) {
      updateOpportunity.persons[0].profession = {
        ihmSector: undefined,
      };
    }

    updateIsActionLoading(true);
    postData(
      'vendors/opportunities/v1/opportunity',
      updateOpportunity,
      () => {
        updateIsActionLoading(false);
        dispatch(
          updateParcoursNavigation({
            name: INFORMATIONS,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        goToNextStep();
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: INFORMATIONS,
            loaded: true,
            actionPageDone: true,
            disabled: false,
            params: {
              hasError: true,
            },
          })
        );
        updateIsActionLoading(false);
        updateError();
      }
    );
  }, [opportunity, person, invalidityFieldsStates, isBirthCountryFR]);

  const getInvalidityState = useCallback(
    (
      field: string,
      value: string | null | undefined,
      onLetterChangeAction?: boolean
    ) => {
      if (field === 'autocompleteBirthCity') {
        return autocompleteValue === undefined
          ? isAutocompleteInvalid(
              appNavUtils?.autoCompleteBirthCityValue
                ?.autocompleteValue as string,
              appNavUtils?.autoCompleteBirthCityValue
                ?.selectedRecordAutocomplete as Record<string, string>
            )
          : isAutocompleteInvalid(value, selectedRecordAutocomplete);
      }

      if (['name', 'firstName'].includes(field)) {
        return isInvalidTextInput(value);
      }

      if (
        (invalidityFieldsStates[field] === false && !onLetterChangeAction) ||
        invalidityFieldsStates[field] === null
      ) {
        return null;
      }

      if (field === 'birthDt') {
        return isDateInvalid(value);
      }

      if (field === KYCEDITABLEFIELDS.BirthCountry) {
        return isComboInputInvalid(value, isBirthCountryValid);
      }

      if (['birthName'].includes(field)) {
        return isInvalidTextInput(value);
      }

      if (field === KYCEDITABLEFIELDS.BirthDepartment) {
        return isComboInputInvalid(value, isBirthDepartmentValid);
      }

      if (field === KYCEDITABLEFIELDS.BirthCity) {
        return isCityInvalidForFranceOrOther(
          value,
          isBirthCityValid,
          regExpInvalidCharacters,
          isBirthCountryFR
        );
      }

      if (field === KYCEDITABLEFIELDS.NationalityIsoCd) {
        return isComboInputInvalid(value, isNationalityIsoCdValid);
      }

      return isStringEmpty(value);
    },
    [
      invalidityFieldsStates,
      person,
      isBirthCountryValid,
      isBirthDepartmentValid,
      isBirthCityValid,
      isNationalityIsoCdValid,
      isBirthCountryFR,
      selectedRecordAutocomplete,
      appNavUtils?.autoCompleteBirthCityValue,
    ]
  );

  const dateOfBirth = useMemo(() => {
    const dateNais = person?.personalInformation?.birthDt;
    const regExp = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');

    if (dateNais !== '' && regExp.test(dateNais)) {
      const date = new Date(dateNais);
      setAge(() => {
        return new Date().getFullYear() - date.getFullYear();
      });
      return `${pad(date.getDate())}/${pad(
        date.getMonth() + 1
      )}/${date.getFullYear()}`;
    }
    setAge(null);
    return '/';
  }, [person?.personalInformation?.birthDt]);

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      const isBirthCityInvalid = isCityInvalidForFranceOrOther(
        person?.personalInformation?.birthCity,
        isBirthCityValid,
        regExpInvalidCharacters,
        isBirthCountryFR
      );

      setButtonClicked((prev) => prev + 1);
      setInvalidityFieldStates({
        title: isStringEmpty(person?.personalInformation?.title),
        birthCity: isBirthCityInvalid,
        birthName: isInvalidTextInput(person?.personalInformation?.birthName),
        birthCountryIsoCd: isComboInputInvalid(
          person?.personalInformation?.birthCountryIsoCd,
          isBirthCountryValid
        ),
        birthDepartment: isComboInputInvalid(
          person?.personalInformation?.birthDepartment,
          isBirthDepartmentValid
        ),
        birthDt: isDateInvalid(person?.personalInformation?.birthDt),
        firstName: isStringEmpty(person?.personalInformation?.firstName),
        nationalityIsoCd: isComboInputInvalid(
          person?.personalInformation?.nationalityIsoCd,
          isNationalityIsoCdValid
        ),
        name: isStringEmpty(person?.personalInformation?.name),
        autocompleteBirthCity: isStringEmpty(autocompleteValue),
        activitySector: isStringEmpty(person?.profession?.ihmSector),
      });

      if (
        showAutoCompletedField &&
        !autocompleteValue &&
        !appNavUtils?.autoCompleteBirthCityValue
      ) {
        setAutocompleteValue(
          (appNavUtils?.autoCompleteBirthCityValue
            ?.autocompleteValue as string) ?? ''
        );
        setSelectedRecordAutocomplete(
          appNavUtils?.autoCompleteBirthCityValue?.autocompleteValue as Record<
            string,
            string
          >
        );
      }

      if (isBirthCityInvalid && isBirthCountryFR) {
        cityRef?.current?.setAttribute('invalid', 'true');
        cityRef?.current?.setAttribute(
          'error-message',
          t('basicInformations.errors.birthCity.required')
        );
      } else if (isBirthCityInvalid && !isBirthCountryFR) {
        freeCityRef?.current?.setAttribute('invalid', 'true');
        freeCityRef?.current?.setAttribute(
          'error-message',
          t('basicInformations.errors.birthCity.required')
        );
      }

      if (isFormValid && !isActionLoading) {
        if (showAutoCompletedField && autocompleteValue) {
          dispatch(
            setAutocompletefieldValue({
              autocompleteValue,
              selectedRecordAutocomplete,
            })
          );
        }
        const birthDtXtn = `${new Date(
          person.personalInformation.birthDt
        ).getFullYear()}${pad(
          new Date(person.personalInformation.birthDt).getMonth() + 1
        )}${pad(new Date(person.personalInformation.birthDt).getDate())}`;
        // Here we are sure to get a valid birthDate so we add XTN call for userId to harmonized with the various
        // applications
        window.seapweb
          ?.setUserId(
            sha256(
              removeSpecialCharacters(
                replaceAccents(
                  opportunity.person.personalInformation.name +
                    opportunity.person.personalInformation.firstName +
                    birthDtXtn
                )
              )
                .normalize('NFKD')
                .toLowerCase()
            )
          )
          .catch(() => {
            console.warn('seapweb::setUserId an error ocurred');
          });

        // Load the XTN method to retrieve the XTN eventId that are very important to them
        window.seapweb
          ?.closeToken(false)
          .then((eventId: string) => {
            // save to database
            saveXtn(eventId);
          })
          .catch(() => {
            console.warn(
              'seapweb::closeToken an error occured when generating the eventId !'
            );
          });

        updateOpportunities();
      }
    },
    [
      updateOpportunities,
      isActionLoading,
      opportunity,
      person,
      isFormValid,
      isBirthCountryValid,
      isBirthDepartmentValid,
      isBirthCityValid,
      isNationalityIsoCdValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
      autocompleteValue,
      showAutoCompletedField,
      appNavUtils?.autoCompleteBirthCityValue,
    ]
  );

  const onChange = useCallback(
    (e: Event, field: string) => {
      if (isUnmount) {
        return;
      }

      let value = (e as unknown as React.ChangeEvent<HTMLInputElement>).target
        .value as any;

      if (field === 'birthDt') {
        const { valueMonth, valueYear, valueDay } = (e as CustomEvent)
          .target as any;
        const {
          newValue,
          age: userAge,
          year: userYear,
        } = getBirthDtValue(
          `${valueDay ?? ''}/${valueMonth ?? ''}/${valueYear ?? ''}`
        );
        value = newValue;
        setAge(newValue === '' ? null : userAge);
        setYear(userYear);
        setMonth(valueMonth ?? '');
        setDay(valueDay ?? '');
      } else if (KYCEDITABLEFIELDS.NationalityIsoCd === field) {
        value = (e as CustomEvent).detail.value;

        if (value && typeof value === 'object') {
          value = value?.test;
          setIsNationalityIsoCdValid(true);
        } else {
          const item = getMatchedItem(
            value,
            'name',
            referentiels?.nationalities
          );

          if (item) {
            value = item?.test;
          }
          setIsNationalityIsoCdValid(!!item);
        }
      } else if (KYCEDITABLEFIELDS.BirthDepartment === field) {
        value = (e as CustomEvent).detail.value;

        if (value && typeof value === 'object') {
          value = value?.test;
          setDepartementCode(value);
          setIsBirthDepartmentValid(true);
        } else {
          const item = getMatchedItem(
            value,
            'name',
            referentiels?.departements
          );

          if (item) {
            value = item?.test;
          }
          setIsBirthDepartmentValid(!!item);
        }
      } else if (KYCEDITABLEFIELDS.BirthCountry === field) {
        value = (e as CustomEvent).detail.value;

        if (value && typeof value === 'object') {
          value = value?.test;
          setIsBirthCountryValid(true);
          if (!isBirthDepartmentValid) {
            dispatch(setCities([]));
          }
          if (value !== 'FR') {
            setIsBirthDepartmentValid(false);
            setIsBirthCityValid(false);
            removeCityDepartementAttributes();
          }
        } else {
          const item = getMatchedItem(value, 'name', referentiels?.countries);

          if (item) {
            if (!isBirthDepartmentValid) {
              dispatch(setCities([]));
            }
            setIsBirthCountryFR(item.test === 'FR');
            if (item.test !== person?.personalInformation?.birthCountryIsoCd) {
              dispatch(
                setPersonalInformation({
                  [KYCEDITABLEFIELDS.BirthDepartment]: '',
                  [KYCEDITABLEFIELDS.BirthCity]: '',
                  [KYCEDITABLEFIELDS.BirthCityInseeCd]: '',
                } as IPersonalInformation)
              );
              setIsBirthDepartmentValid(false);
              setIsBirthCityValid(false);
              removeCityDepartementAttributes();
            }
            value = item?.test;
          }
          setIsBirthCountryValid(!!item);
        }
      } else if (field === KYCEDITABLEFIELDS.BirthCity && isBirthCountryFR) {
        value = (e as CustomEvent).detail.value;
        if (value && typeof value === 'object') {
          const val = value;
          value = val.name;
          setIsBirthCityValid(true);
          dispatch(
            setPersonalInformation({
              [KYCEDITABLEFIELDS.BirthCityInseeCd]: val.test,
            } as IPersonalInformation)
          );
        } else {
          const item = getMatchedItem(value, 'name', referentiels?.cities);

          if (item) {
            dispatch(
              setPersonalInformation({
                [KYCEDITABLEFIELDS.BirthCityInseeCd]: item.test,
              } as IPersonalInformation)
            );
            value = item.name;
          }
          setIsBirthCityValid(!!item);
        }
      } else if (
        field === 'birthName' ||
        (field === KYCEDITABLEFIELDS.BirthCity && !isBirthCountryFR)
      ) {
        const convertedValue = (value ?? '')
          .trim()
          .replace(
            new RegExp("[^a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-]+", 'i'),
            ''
          );

        value = wholeWordCapitalize(convertedValue);
      } else if (['name', 'firstName'].includes(field)) {
        value = capitalize(value);
      }

      dispatch(
        setPersonalInformation({
          [field]: value?.trim(),
        } as IPersonalInformation)
      );
    },
    [
      referentiels,
      isUnmount,
      isBirthDepartmentValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
      opportunity,
    ]
  );

  // civility
  const onChangeCivility = useCallback(
    (event: Event) => {
      onChange(event as CustomEvent, 'title');
    },
    [isUnmount]
  );

  const onChangeName = useCallback(
    (event: Event) => {
      onChange(event as CustomEvent, 'name');
    },
    [isUnmount]
  );

  const onChangeFirstName = useCallback(
    (event: Event) => {
      onChange(event as CustomEvent, 'firstName');
    },
    [isUnmount]
  );

  // birthName
  const onChangeBirthName = useCallback(
    (event: Event) => {
      onChange(event as CustomEvent, 'birthName');
    },
    [isUnmount]
  );

  const inputErrorMsg = useCallback((label: string) => {
    return JSON.stringify([
      {
        regexp: '^.{2,30}$',
        errorMessage: t('basicInformations.errors.' + label),
      },
    ]);
  }, []);

  // birthDt
  const onChangeBirthDt = useCallback(
    (event: Event) => {
      onChange(event as CustomEvent, 'birthDt');
    },
    [isUnmount]
  );

  // activitySector (mobile)
  const onChangeSector = (e, item?: IListItem) => {
    setInvalidityFieldStates((prev) => ({
      ...prev,
      activitySector: isStringEmpty(item?.value as string),
    }));
    if (typeof item === 'object') {
      dispatch(
        setProfession({
          ihmSector: item?.value,
        } as IProfessionInput)
      );
    }
  };

  // activitySector (desktop)
  const onActivitySectorChange = (value: string) => {
    setInvalidityFieldStates((prev) => ({
      ...prev,
      activitySector: isStringEmpty(value),
    }));
    if (value && typeof value === 'string')
      dispatch(
        setProfession({
          ihmSector: value?.trim(),
        } as IProfessionInput)
      );
  };

  const getBirthDtErrorMessage = useCallback(() => {
    return JSON.stringify([
      {
        regexp: '.+',
        errorMessage: t('basicInformations.errors.birthDt.required'),
      },
      {
        regexp: `^((0[1-9]{1}|[1-2]{1}[0-9]{1})|3[0-1])/(${allowedMonthList.join(
          '|'
        )})/(${maxYear})$|^((${allowedDayList.join('|')})/(${pad(
          maxMonth
        )})/${maxYear})$|^(0[1-9]{1}|[1-2]{1}[0-9]{1}|3[0-1])/(0[1-9]{1}|1[0-2]{1})/(${allowedYearList.join(
          '|'
        )})$`,
        errorMessage: t(
          getYearErrorMessage(age, year, day, month).errorMessage
        ),
      },
    ]);
  }, [t, age, year, day, month]);

  // BirthCountry
  const onSelectBirthCountry = useCallback(
    (e: Event) => {
      const value = (e as CustomEvent).detail.value?.test;
      setIsBirthCountryFR(value === 'FR');
      if (value !== 'FR') {
        dispatch(
          setPersonalInformation({
            [KYCEDITABLEFIELDS.BirthCity]: '',
          } as IPersonalInformation)
        );
      }
      onChange(e, KYCEDITABLEFIELDS.BirthCountry);
    },
    [
      referentiels,
      isUnmount,
      isBirthDepartmentValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
    ]
  );

  const onInputChangeBirthCountry = useCallback(
    (e: Event) => {
      const value = (e as unknown as React.ChangeEvent<HTMLInputElement>).target
        .value as any;
      if (['', null, undefined].includes(value)) {
        onChange(e, KYCEDITABLEFIELDS.BirthCountry);
      }
    },
    [
      referentiels,
      isUnmount,
      isBirthDepartmentValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
    ]
  );

  const onLetterChangedBirthCountry = useCallback(
    (e: Event) => {
      onChange(e as CustomEvent, KYCEDITABLEFIELDS.BirthCountry);
    },
    [
      referentiels,
      isUnmount,
      isBirthDepartmentValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
    ]
  );

  const birthCountryCustomPattern = useMemo(
    () =>
      customValidityPattern('.+', t('basicInformations.errors.birthCountry')),
    [t]
  );

  // BirthDepartment
  const onChangeBirhtDepartment = useCallback(
    (e: Event) => {
      onChange(e, KYCEDITABLEFIELDS.BirthDepartment);
    },
    [
      referentiels,
      isUnmount,
      isBirthDepartmentValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
    ]
  );

  const birthDepartmentCustomPattern = useMemo(
    () =>
      customValidityPattern(
        '.+',
        t('basicInformations.errors.birthDepartment')
      ),
    [t]
  );

  // brithCity
  const onChangeBirhtCity = useCallback(
    (e: Event) => {
      onChange(e as CustomEvent, KYCEDITABLEFIELDS.BirthCity);
    },
    [
      referentiels,
      isUnmount,
      isBirthDepartmentValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
    ]
  );

  const onBlurAutocomplete = (e, rec, internalValue) => {
    if (internalValue === undefined) {
      setSelectedRecordAutocomplete(
        appNavUtils?.autoCompleteBirthCityValue
          ?.selectedRecordAutocomplete as Record<string, string>
      );
      setAutocompleteValue(
        appNavUtils?.autoCompleteBirthCityValue?.autocompleteValue as string
      );
    } else {
      setSelectedRecordAutocomplete(rec);
      setAutocompleteValue(internalValue);
    }
  };

  const customValidityPatternBirthCityFr = useMemo(
    () =>
      customValidityPattern(
        '.+',
        t('basicInformations.errors.birthCity.required')
      ),
    [t]
  );

  const customValidityPatternBirthCityNotFr = useMemo(
    () =>
      JSON.stringify([
        {
          regexp: "^[a-zA-Zzàâçèéêîôùû'´ëä -]+$",
          errorMessage: t('basicInformations.errors.birthCity.format'),
        },
        {
          regexp: '^(.+){1,30}$',
          errorMessage: t('basicInformations.errors.birthCity.maxChar'),
        },
        {
          regexp: '.+',
          errorMessage: t('basicInformations.errors.birthCity.required'),
        },
      ]),
    [t]
  );

  // Nationality
  const onChangeNationality = useCallback(
    (e: Event) => {
      onChange(e as CustomEvent, KYCEDITABLEFIELDS.NationalityIsoCd);
    },
    [
      referentiels,
      isUnmount,
      isBirthDepartmentValid,
      isBirthCountryFR,
      cityRef,
      freeCityRef,
    ]
  );

  const nationalityCustomValidityPattern = useMemo(
    () =>
      customValidityPattern('.+', t('basicInformations.errors.nationality')),
    [t]
  );

  const birthCountryValue = useMemo(() => {
    const birthCountry = person?.personalInformation?.birthCountryIsoCd ?? '';
    return isBirthCountryValid
      ? getMatchedItem(birthCountry, 'test', referentiels?.countries)?.name ??
          ''
      : birthCountry;
  }, [
    isBirthCountryValid,
    referentiels,
    opportunity?.person?.personalInformation?.birthCountryIsoCd,
  ]);

  const birthDepartmentValue = useMemo(() => {
    const birthDepartment = person?.personalInformation?.birthDepartment ?? '';
    return isBirthDepartmentValid
      ? getMatchedItem(birthDepartment, 'test', referentiels?.departements)
          ?.name ?? ''
      : birthDepartment;
  }, [
    isBirthDepartmentValid,
    referentiels,
    opportunity?.person?.personalInformation?.birthDepartment,
  ]);

  const birthCityValue = useMemo(() => {
    const birthCity = person?.personalInformation?.birthCity ?? '';
    if (!isBirthCountryFR) {
      return wholeWordCapitalize(birthCity);
    }

    return isBirthCityValid
      ? getMatchedItem(birthCity, 'name', referentiels?.cities)?.name ?? ''
      : birthCity;
  }, [
    isBirthCityValid,
    referentiels,
    isBirthCountryFR,
    person?.personalInformation?.birthCity,
    opportunity?.person?.personalInformation?.birthCity,
  ]);

  const nationalityIsoCd = useMemo(() => {
    const origin = person?.personalInformation?.nationalityIsoCd ?? '';
    const nationality = !isStringEmpty(origin) ? origin : 'FR';

    return isNationalityIsoCdValid
      ? getMatchedItem(nationality, 'test', referentiels?.nationalities)
          ?.name ?? ''
      : origin;
  }, [
    isNationalityIsoCdValid,
    referentiels,
    opportunity?.person?.personalInformation?.nationalityIsoCd,
  ]);

  const openPopUpRetour = useCallback(() => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Formulaire KYC : Retour',
    });
    onShowModalLeave(true);
  }, []);

  const openPopUpEdit = useCallback(() => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Formulaire KYC : Modifier',
    });
    onShowModal(true);
  }, []);

  const editButtonProps = { onButtonClick: () => openPopUpEdit() };

  const onShowModalFn = () => {
    if (isLoading) {
      return;
    }
    onShowModal(false);
  };

  const returnToWebMerchant = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    goToWebMarchand(
      getUrlCancel(),
      "du bouton modifier dans la vérification d'indentité",
      () => {
        dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.ANUL));
      },
      () => {
        updateError();
      }
    );
  };

  const onChangeAutoCompletedField = (res, concatenedRes) => {
    setSelectedRecordAutocomplete(res);
    setAutocompleteValue(concatenedRes);
    setIsBirthCountryFR(res?.codePays === 'FR');
    dispatch(
      setPersonalInformation({
        [KYCEDITABLEFIELDS.BirthCityInseeCd]:
          res?.codePays === 'FR' ? res?.codeInsee : '',
        [KYCEDITABLEFIELDS.BirthDepartment]: res?.province,
        [KYCEDITABLEFIELDS.BirthCountry]: res?.codePays,
        [KYCEDITABLEFIELDS.BirthCity]: res?.localite,
      } as IPersonalInformation)
    );
  };

  const originalName = useMemo(() => person?.personalInformation?.name, []);
  const originalFirstName = useMemo(
    () => person?.personalInformation?.firstName,
    []
  );

  const onSuggestionAutoCompletedFieldClick = () => {
    setShowAutoCompletedField(false);
    dispatch(setIsFilledWithAutoComplete(false));
    dispatch(
      setPersonalInformation({
        [KYCEDITABLEFIELDS.BirthCityInseeCd]: '',
        [KYCEDITABLEFIELDS.BirthDepartment]: '',
        [KYCEDITABLEFIELDS.BirthCountry]: '',
        [KYCEDITABLEFIELDS.BirthCity]: '',
      } as IPersonalInformation)
    );
  };
  const [isBirthNamaFocused, setIsBirthNameFocused] = useState<boolean>(false);
  const [showNameBubbleInfo, setShowNameBubbleInfo] = useState<boolean>(false);
  const [showFirstnameBubbleInfo, setShowFirstnameBubbleInfo] =
    useState<boolean>(false);

  return {
    formRef,
    person,
    dateOfBirth,
    birthCountryValue,
    isBirthCountryFR,
    birthDepartmentValue,
    birthCityValue,
    cityRef,
    freeCityRef,
    nationalityIsoCd,
    personalDetails,
    contentDetails,
    isFormValid,
    showModal,
    showModalLeave,
    inputErrorMsg,
    birthDepartmentCustomPattern,
    birthCountryCustomPattern,
    customValidityPatternBirthCityFr,
    customValidityPatternBirthCityNotFr,
    nationalityCustomValidityPattern,
    editButtonProps,
    isActionLoading,
    t,
    autocompleteBirthCity: autocompleteValue,
    defaultAutocompleteValue: appNavUtils?.autoCompleteBirthCityValue
      ?.autocompleteValue as string,
    showAutoCompletedField,
    invalidityFieldsStates,
    isDesktop,
    sectors,
    mobileSectors,
    isLoading,
    onChangeSector,
    onActivitySectorChange,
    getBirthDtErrorMessage,
    openPopUpRetour,
    onShowModal,
    onShowModalFn,
    openPopUpEdit,
    onSubmit,
    onShowModalLeave,
    onShowModalLeaveFn,
    onChangeCivility,
    onChangeBirthName,
    onChangeBirthDt,
    onSelectBirthCountry,
    onInputChangeBirthCountry,
    onLetterChangedBirthCountry,
    onChangeBirhtDepartment,
    onChangeBirhtCity,
    onChangeNationality,
    updateOpportunities,
    returnToWebMerchant,
    // DOPC & OPTIN
    dopcOptin,
    onUpdateOptin,
    onUpdateDopc,
    // for test purpose
    age,
    year,
    month,
    day,
    onChange,
    setInvalidityFieldStates,
    getInvalidityState,
    onChangeAutoCompletedField,
    onSuggestionAutoCompletedFieldClick,
    onBlurAutocomplete,
    setShowAutoCompletedField,
    setIsFormValid,
    isBirthNamaFocused,
    setIsBirthNameFocused,

    showNameBubbleInfo,
    setShowNameBubbleInfo,

    showFirstnameBubbleInfo,
    setShowFirstnameBubbleInfo,

    onChangeName,
    onChangeFirstName,
    wordCompare,
    originalName,
    originalFirstName,
  };
};

export default useInformationForm;
