import { EventAnalytic } from '@sweb-front/types';

export const trackEvent = (event: EventAnalytic) => {
  console.log('-----------BEFORE PUSH-----------');
  console.table((window as any).dataLayer);
  console.log('-----------EVENT-A-AJOUTER-------');
  console.table(event);

  (window as any).dataLayer.push(event);

  console.log('-----------NO TIMEOUT-----------');
  console.table((window as any).dataLayer);

  const timer = setTimeout(() => {
    console.log('-----------WITH TIMEOUT-----------');
    console.table((window as any).dataLayer);
    console.log('-----------FIN ACTION PUSH-----------');
    clearTimeout(timer);
  }, 500);
};
