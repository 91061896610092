import { RedirectWebMerchantFooter } from '@sweb-front/components';
import { COOKIES, MENTIONSLEGALES } from '@vat/configuration';
import { useAppOptions } from '@vat/utils';
import { useTranslation } from 'react-i18next';
import {
  FooterContainer,
  FooterStyledLink,
  FooterStyledNativeLink,
  FooterStyledParagraph,
  FooterStyledSpan,
  FooterSeparator,
} from './styles';
import { useState } from 'react';
import { ModalLeave } from '@vat/layouts';

export type FooterProps = {
  companyCode?: number;
  opposedCommercialOffers?: boolean;
  canDirectlyShowCmp: boolean;
  urlPath?: string;
};

const Footer = ({
  companyCode = 100,
  opposedCommercialOffers = true,
  canDirectlyShowCmp = false,
}) => {
  const { t } = useTranslation();
  const { appOptions } = useAppOptions();
  const [showModalLeave, setShowModalLeave] = useState(false);
  const toogleModal = () => {
    setShowModalLeave((prev) => !prev);
  };

  return appOptions.isMounted || canDirectlyShowCmp ? (
    <>
      <FooterContainer id="footer">
        <FooterSeparator />
        {!canDirectlyShowCmp ? (
          <RedirectWebMerchantFooter
            text={t('footer.backToWebMerchant')}
            onClick={toogleModal}
          />
        ) : (
          ''
        )}

        <FooterStyledLink
          role="link"
          target="_blank"
          rel="noopener noreferrer"
          to={`${MENTIONSLEGALES}`}
        >
          {t('footer.legalMentions')}
        </FooterStyledLink>
        <FooterStyledLink role="link" to={`${COOKIES}`}>
          {t('footer.cookies')}
        </FooterStyledLink>
        <div>
          <FooterStyledSpan>
            {t('footer.informationProcessing')}
          </FooterStyledSpan>
          <p> </p>
          <FooterStyledParagraph>
            {t('footer.paragraph.firstPart')} BNP Paribas Personal Finance -
            Service consommateurs - 95908 Cergy Pontoise Cedex 09 :{' '}
            <FooterStyledNativeLink
              href="mailto:consommateur.donneespersonnelles@bnpparibas-pf.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              consommateur.donneespersonnelles@bnpparibas-pf.com
            </FooterStyledNativeLink>{' '}
            {t('footer.paragraph.secondPart')}{' '}
            <FooterStyledNativeLink
              role="link"
              href={
                companyCode === 140
                  ? 'https://broadcast.mediahub.bnpparibas/mh/external/master/32ce434f-cdbf-4cc7-a263-bdb1ca90f4d9'
                  : 'https://broadcast.mediahub.bnpparibas/mh/external/master/c4314d4f-8e56-4d3a-8715-6292ea8e79ec'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.paragraph.dataSecurityPolicy')}
            </FooterStyledNativeLink>
            .
          </FooterStyledParagraph>
        </div>
        {!opposedCommercialOffers ? (
          <FooterStyledParagraph data-testid="commercial-offers-paragraph">
            {t('footer.commercialOffers.firstPart')}{' '}
            <FooterStyledNativeLink
              role="link"
              href="https://www.bloctel.gouv.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.bloctel.gouv.fr
            </FooterStyledNativeLink>
            . {t('footer.commercialOffers.secondPart')}
          </FooterStyledParagraph>
        ) : (
          <></>
        )}
      </FooterContainer>
      {showModalLeave && (
        <ModalLeave
          title={t('LeavePage.title')}
          description={t('LeavePage.description')}
          descriptionProgress={t('LeavePage.descriptionProgress')}
          confirmation={t('LeavePage.confirmation')}
          close={toogleModal}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default Footer;
